import axios, { AxiosResponse } from "axios";
import axiosRetry from "axios-retry";

type AxiosType = "get" | "post" | "put";
interface IObject {
    [key: string]: any;
}
const apiDirectCallAWS4 = async (
    endpoint: string,
    verb: AxiosType = "get",
    params: IObject = {},
    options: IObject = {},
    fullUrl: boolean = false
) => {
    const tokenApiResponse = await fetch("/api/user/getToken", {
        method: "POST",
    });
    const tokenData = await tokenApiResponse.json();

    if (tokenApiResponse.status !== 200) {
        console.log("error getting token from tokenApi");
        const axiosResponse: AxiosResponse<any, any> = {
            status: tokenApiResponse.status,
            statusText: tokenApiResponse.statusText,
            data: {},
            headers: {},
            config: {},
        };
        return axiosResponse;
        //return tokenApiResponse;
    }

    const accessKeyId = tokenData.Credentials.AccessKeyId;
    const secretAccessKey = tokenData.Credentials.SecretKey;
    const sessionToken = tokenData.Credentials.SessionToken;

    const aws4Request = await fetch("/api/aws4Request", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            _accessKeyId: accessKeyId,
            _secretAccessKey: secretAccessKey,
            _sessionToken: sessionToken,
            _endpoint: endpoint,
            _verb: verb,
            _params: params,
            _options: options,
            _fullUrl: fullUrl,
        }),
    });

    const responseData = await aws4Request.json();
    //console.log("apiDirectCallAWS4 responseData", responseData);

    return responseData;
};

// const apiDirectCallAWS4 = async (
//     endpoint: string,
//     verb: AxiosType = "get",
//     params: IObject = {},
//     options: IObject = {},
//     fullUrl: boolean = false
// ) => {
//     const initialEndPoint = process.env.NEXT_PUBLIC_REACT_APP_API_ADDRESS;
//     const tokenApiResponse = await fetch("/api/user/getToken", {
//         method: "POST",
//     });
//     const tokenData = await tokenApiResponse.json();

//     if (tokenApiResponse.status !== 200) {
//         console.log("error getting token from tokenApi");
//         const axiosResponse: AxiosResponse<any, any> = {
//             status: tokenApiResponse.status,
//             statusText: tokenApiResponse.statusText,
//             data: {},
//             headers: {},
//             config: {},
//         };
//         return axiosResponse;
//         //return tokenApiResponse;
//     }

//     const accessKeyId = tokenData.Credentials.AccessKeyId;
//     const secretAccessKey = tokenData.Credentials.SecretKey;
//     const sessionToken = tokenData.Credentials.SessionToken;

//     const aws4Interceptor = (await import("aws4-axios")).aws4Interceptor;
//     const interceptor = aws4Interceptor(
//         {
//             region: "us-east-1",
//             service: "execute-api",
//         },
//         {
//             accessKeyId,
//             secretAccessKey,
//             sessionToken,
//         }
//     );

//     const client = axios.create();
//     client.interceptors.request.use(interceptor);
//     client.defaults.withCredentials = false;

//     client.interceptors.response.use(
//         (response) => response,
//         (error) => {
//             if (typeof error.response === "undefined") {
//                 console.log("axios aws4 error response undefined", error);
//             }
//             console.log("axios aws4 error response", error);
//             return Promise.reject(error);
//         }
//     );

//     axiosRetry(client, {
//         retryDelay: (retryCount) => {
//             return retryCount * 1000;
//         },
//     });

//     const optionsRequest = options;

//     let endPointLocal = initialEndPoint + endpoint;
//     if (fullUrl) {
//         endPointLocal = endpoint;
//     }
//     if (verb === "get") {
//         const response = await client.get(endPointLocal, optionsRequest);
//         return response;
//     } else if (verb === "post") {
//         const response = await client.post(
//             endPointLocal,
//             params,
//             optionsRequest
//         );
//         return response;
//     } else {
//         const response = await client.put(
//             endPointLocal,
//             params,
//             optionsRequest
//         );
//         return response;
//     }
// };

export default apiDirectCallAWS4;
